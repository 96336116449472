// A component with a navbar and a dialog window for creating a new list.

import React, {useEffect, useState} from "react";

import logoEnglishLanguage from "../Imgs/LogoEnglish.jpg";
import logoDutchLanguage from "../Imgs/logoNetherlands.webp";
import {Drawer, ModalClose} from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import Menu from '@mui/icons-material/Menu';

function NavbarComponent() {

    const [language, setLanguage] = useState('nl');
    const [isMobile, setIsMobile] = useState(false);
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
        setOpen(open);
    }

    useEffect(() => {
        if (window.innerWidth < 1050) {
            setIsMobile(true);
        }
    });

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            setLanguage(storedLanguage);
        }
    }, []);

    const currentPage = window.location.href;
    // split currentPage by / and get the last element of the array
    const currentPageName = currentPage.split("/").pop();
    console.log(currentPageName)

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
        localStorage.setItem('language', lang);

        // Reload the page to apply the language change
        window.location.reload();
    };

    let drawerMenuButton;

    if (isMobile) {
        drawerMenuButton = <IconButton className='navigation-drawer navigation-drawer-button' variant="outlined"
                                           color="neutral" onClick={() => setOpen(true)}>
            <Menu/>
        </IconButton>
    }

    if (isMobile) {
        drawerMenuButton = <IconButton className='navigation-drawer navigation-drawer-button' variant="outlined"
                                       color="neutral" onClick={() => setOpen(true)}>
            <Menu/>
        </IconButton>


    }

    if (language === 'nl') {

        // navigation
        let navigationHome = <a href="/#">
            <div className="navigation-element">
                <div className="navigation-element-text">Home</div>
            </div>
        </a>

        let navigationAboutUs = <a href="/about">
            <div className="navigation-element">
                <div className="navigation-element-text">Over ons</div>
            </div>
        </a>

        let navigationAgenda = <a href="/agenda">
            <div className="navigation-element">
                <div className="navigation-element-text">Agenda 2024</div>
            </div>
        </a>

        let navigationVoorbereiding = <a href="/voorbereding">
            <div className="navigation-element">
                <div className="navigation-element-text">Voorbereiding</div>
            </div>
        </a>

        let navigationFAQ = <a href="/faq">
            <div className="navigation-element">
                <div className="navigation-element-text">FAQ</div>
            </div>
        </a>


        switch (currentPageName) {
            case 'home':
            case '':
            case '#':
                navigationHome = <a href="/#">
                    <div className="navigation-element">
                        <div className="navigation-element-text-active">Home</div>
                    </div>
                </a>
                break;

            case 'agenda':
                navigationAgenda = <a href="/agenda">
                    <div className="navigation-element">
                        <div className="navigation-element-text-active">Agenda 2024</div>
                    </div>
                </a>
                break;

            case 'voorbereding':
                navigationVoorbereiding = <a href="/voorbereding">
                    <div className="navigation-element">
                        <div className="navigation-element-text-active">Voorbereiding</div>
                    </div>
                </a>
                break;

            case 'faq':
                navigationFAQ = <a href="/faq">
                    <div className="navigation-element">
                        <div className="navigation-element-text-active">FAQ</div>
                    </div>
                </a>
                break;

            case 'about':
                navigationAboutUs = <a href="/about">
                    <div className="navigation-element">
                        <div className="navigation-element-text-active">Over ons</div>
                    </div>
                </a>
                break;
        }

        return (
            <div>
                <div className="NavbarContainer">
                    <a href="/#">
                        <div className="logo">
                            <div className="logo-text">Ayahuasca Nederland</div>
                        </div>
                    </a>

                    <div className="navigation">

                        {isMobile ? null : navigationHome}
                        {isMobile ? null : navigationAboutUs}
                        {isMobile ? null : navigationAgenda}
                        {isMobile ? null : navigationVoorbereiding}
                        {isMobile ? null : navigationFAQ}

                        {drawerMenuButton}

                        <Drawer className='navigation-drawer' open={open} onClose={toggleDrawer(false)}
                                anchor="top"
                                color="neutral"
                                invertedColors={false}
                                size="lg"
                                variant="solid"
                        >
                            <ModalClose id="close-icon" sx={{position: 'initial'}}/>

                            {navigationHome}
                            {navigationAboutUs}
                            {navigationAgenda}
                            {navigationVoorbereiding}
                            {navigationFAQ}
                            <a href="/contact">
                                <div className="navigation-element-text">Contact us</div>
                            </a>
                        </Drawer>
                        <a className='btn-contact-us' href="/contact">
                            <div className="btn-newlist">
                                <div className="btn-newlist-text">Contact us</div>
                            </div>
                        </a>

                        <div className="navigation-divider">
                            <svg xmlns="http://www.w3.org/2000/svg" width="2" height="55" viewBox="0 0 2 92" fill="none"
                                 className="navigation-divider">
                                <path d="M1 0V92" stroke="black" stroke-width="2"></path>
                            </svg>
                        </div>

                        <div onClick={() => handleLanguageChange('en')} className="navigation-language">
                            <img src={logoEnglishLanguage} alt="Github" className="navigation-language-icon"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {

        // navigation
        let navigationHome = <a href="/#">
            <div className="navigation-element">
                <div className="navigation-element-text">Home</div>
            </div>
        </a>

        let navigationAboutUs = <a href="/about">
            <div className="navigation-element">
                <div className="navigation-element-text">About us</div>
            </div>
        </a>

        let navigationAgenda = <a href="/agenda">
            <div className="navigation-element">
                <div className="navigation-element-text">Agenda 2024</div>
            </div>
        </a>

        let navigationVoorbereiding = <a href="/voorbereding">
            <div className="navigation-element">
                <div className="navigation-element-text">Preparations</div>
            </div>
        </a>

        let navigationFAQ = <a href="/faq">
            <div className="navigation-element">
                <div className="navigation-element-text">FAQ</div>
            </div>
        </a>


        switch (currentPageName) {
            case 'home':
            case '':
            case '#':
                navigationHome = <a href="/#">
                    <div className="navigation-element">
                    <div className="navigation-element-text-active">Home</div>
                    </div>
                </a>
                break;

            case 'agenda':
                navigationAgenda = <a href="/agenda">
                    <div className="navigation-element">
                        <div className="navigation-element-text-active">Agenda 2024</div>
                    </div>
                </a>
                break;

            case 'voorbereding':
                navigationVoorbereiding = <a href="/voorbereding">
                    <div className="navigation-element">
                        <div className="navigation-element-text-active">Preparations</div>
                    </div>
                </a>
                break;

            case 'faq':
                navigationFAQ = <a href="/faq">
                    <div className="navigation-element">
                        <div className="navigation-element-text-active">FAQ</div>
                    </div>
                </a>
                break;

            case 'about':
                navigationAboutUs = <a href="/about">
                    <div className="navigation-element">
                        <div className="navigation-element-text-active">About us</div>
                    </div>
                </a>
                break;
        }

        return (
            <div>
                <div className="NavbarContainer">
                    <a href="/#">
                        <div className="logo">
                            <div className="logo-text">Ayahuasca Netherlands</div>
                        </div>
                    </a>

                    <div className="navigation">

                        {isMobile ? null : navigationHome}
                        {isMobile ? null : navigationAboutUs}
                        {isMobile ? null : navigationAgenda}
                        {isMobile ? null : navigationVoorbereiding}
                        {isMobile ? null : navigationFAQ}

                        {drawerMenuButton}

                        <Drawer className='navigation-drawer' open={open} onClose={toggleDrawer(false)}
                                anchor="top"
                                color="neutral"
                                invertedColors={false}
                                size="lg"
                                variant="solid"
                        >
                            <ModalClose id="close-icon" sx={{position: 'initial'}}/>

                            {navigationHome}
                            {navigationAboutUs}
                            {navigationAgenda}
                            {navigationVoorbereiding}
                            {navigationFAQ}
                            <a href="/contact">
                                 <div className="navigation-element-text">Contact us</div>
                            </a>
                        </Drawer>

                        <a className='btn-contact-us' href="/contact">
                            <div className="btn-newlist">
                                <div className="btn-newlist-text">Contact us</div>
                            </div>
                        </a>

                        <div className="navigation-divider">
                            <svg xmlns="http://www.w3.org/2000/svg" width="2" height="55" viewBox="0 0 2 92" fill="none"
                                 className="navigation-divider">
                                <path d="M1 0V92" stroke="black" stroke-width="2"></path>
                            </svg>
                        </div>

                        <div onClick={() => handleLanguageChange('nl')} className="navigation-language">
                            <img src={logoDutchLanguage} alt="Github" className="navigation-language-icon"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


}

export default NavbarComponent;
