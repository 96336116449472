// A component that contains the main content of the main page.

import React, {useEffect, useState} from "react";
import infoImage from "../Imgs/voorwie.jpg";
import Footer from "./FooterComponent";
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import {DialogContent, DialogTitle, Modal, ModalDialog} from "@mui/joy";
import Stack from "@mui/joy/Stack";
import { useForm, ValidationError } from '@formspree/react';
import Textarea from '@mui/joy/Textarea';
import axios from "axios";

const AgendaMainContent = () => {

    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get('https://place-spokesman-cube-dependence.trycloudflare.com/api/getEvents');
                setEvents(response.data);
            } catch (error) {
                console.error("Failed to fetch events:", error);
            }
        };
        fetchEvents();
    }, []);

    // old
  //  const ContactForm = () => {
  //      const [state, handleSubmit] = useForm("xrgorbgv");
  //      if (state.succeeded) {
  //          return <p>Thanks for joining!</p>;
  //      }
  //      return (
  //          <form onSubmit={handleSubmit}>
  //              <FormControl>
  //                  <FormLabel>Email</FormLabel>
  //                  <Input id="email" type="email" name="email" required/>
  //                  <ValidationError
  //                      prefix="Email"
  //                      field="email"
  //                      errors={state.errors}
  //                  />
  //              </FormControl>
  //              <FormControl>
  //                  <FormLabel>Message</FormLabel>
  //                  <Textarea
  //                      id="message"
  //                      name="message"
  //                  />
  //                  <ValidationError
  //                      prefix="Message"
  //                      field="message"
  //                      errors={state.errors}
  //                  />
  //              </FormControl>
  //              <br></br>
  //              <FormControl>
  //                  <Button type="submit" disabled={state.submitting}>
  //                      Submit
  //                  </Button>
  //              </FormControl>
  //          </form>
  //  );
  //  };

    // new
    const ContactForm = () => {
        const [email, setEmail] = useState('');
        const [message, setMessage] = useState('');

        const tempContactFunction = (e) => {

            e.preventDefault();
            console.log(email, message);

            axios.post('https://place-spokesman-cube-dependence.trycloudflare.com/api/sendMessage', {
                chatId: '586081089',
                message: `Email: ${email}\n\nMessage: ${message}`
            })
                .then((response) => {
                    console.log(response);
                    setOpen(false);
                }, (error) => {
                    console.log(error);
                    setOpen(false);
                });

        }

        return (
                      <form onSubmit={tempContactFunction}>
                          <FormControl>
                              <FormLabel>Email</FormLabel>
                              <Input
                                  id="email"
                                  type="email"
                                  name="email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                              />
                          </FormControl>
                          <FormControl>
                              <FormLabel>Message</FormLabel>
                              <Textarea
                                  id="message"
                                  name="message"
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                              />
                          </FormControl>
                          <br></br>
                          <FormControl>
                              <Button type="submit">
                                  Submit
                              </Button>
                          </FormControl>
                      </form>
        );
    };

    // EventCard Component
    const EventCard = ({ event, contactOnClick, signUpOnClick }) => {
        const formattedEventStart = formatDate(event.event_start);
        const formattedEventEnd = formatDate(event.event_end);

        console.log(event);


        return (
            <Card className='agenda-card' sx={{ width: '100%' }}>
                <div>
                    <Typography level="title-lg">{event.event_name}</Typography>
                    <Typography level="body-sm">{formattedEventStart} - {formattedEventEnd}</Typography>
                    {event.places_left} {language === 'en' ? 'places left' : 'plaatsen over'}
                </div>
                <AspectRatio minHeight="120px" maxHeight="200px">
                    <img
                        src={event.image_url}
                        loading="lazy"
                        alt=""
                    />
                </AspectRatio>
                <CardContent orientation="horizontal">
                    <div>
                        <Typography level="body-xm">Reservering:</Typography>
                        <Typography fontSize="lg" fontWeight="lg">
                            €{event.event_reservation}
                        </Typography>
                    </div>
                    <div>
                        <Typography level="body-xm">Remaining fee:</Typography>
                        <Typography fontSize="lg" fontWeight="lg">
                            €{event.event_price}
                        </Typography>
                    </div>
                    <Button
                        onClick={(e) => signUpOnClick(event.event_name)}
                        variant="solid"
                        size="md"
                        color="primary"
                        sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }}
                    >
                        Contact us
                    </Button>
                </CardContent>
            </Card>
        );
    };

    const formatDate = dateString => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    const [event, setEvent] = useState('None');
    const [language, setLanguage] = useState('nl');

    const [open, setOpen] = useState(false);

    const contactOnClick = () => {
        setOpen(true);
    };

    const signUpOnClick = (event) => {
        console.log(event)
        setEvent(event.event_name);
        contactOnClick();
    }

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            setLanguage(storedLanguage);
        }
    }, []);

    if (language === 'en') {
        return (
            <div className="main-background-agenda">
                <div className="agenda-container">
                    <Modal open={open} onClose={() => setOpen(false)}>
                        <ModalDialog>
                            <DialogTitle>Sign up for the event</DialogTitle>
                            <DialogContent>Please fill your contact information so we can contact you back.</DialogContent>
                            <div
                            >
                                <Stack spacing={2}>
                                    <FormControl>
                                        <FormLabel>Event</FormLabel>
                                        <Input readOnly value={event} required />
                                    </FormControl>
                                    <ContactForm />
                                </Stack>
                            </div>
                        </ModalDialog>
                    </Modal>
                    <div className="agenda-row">
                            {events.length > 0 ? (
                                events.map((event, index) => (
                                    <EventCard
                                        className='agenda-card'
                                        key={index}
                                        event={event}
                                        signUpOnClick={() => signUpOnClick(event)}
                                    />
                                ))
                            ) : (
                                <div>Loading events...</div>
                            )}
                    </div>
                </div>
            </div>
        );
    } else if (language === 'nl') {
        return (
            <div className="main-background-agenda">
                <div className="agenda-container">
                    <Modal open={open} onClose={() => setOpen(false)}>
                        <ModalDialog>
                            <DialogTitle>Aanmelden voor het ceremonie</DialogTitle>
                            <DialogContent>Vul je contactgegevens in zodat we contact met je kunnen opnemen.</DialogContent>
                            <div
                            >
                                <Stack spacing={2}>
                                    <FormControl>
                                        <FormLabel>Event</FormLabel>
                                        <Input readOnly value={event} required />
                                    </FormControl>
                                    <ContactForm />
                                </Stack>
                            </div>
                        </ModalDialog>
                    </Modal>
                    <div className="agenda-row">
                        {events.length > 0 ? (
                            events.map((event, index) => (
                                <EventCard
                                    className='agenda-card'
                                    key={index}
                                    event={event}
                                    signUpOnClick={() => signUpOnClick(event)}
                                />
                            ))
                        ) : (
                            <div>Loading events...</div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

};

export default AgendaMainContent;
